<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update Device</v-card-title>
    <v-row>

      <v-col cols="6">
        <v-autocomplete dense outlined label="User" :items="listUsers" v-model="item.user_id"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Tên" v-model="item.name"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Imei" v-model="item.imei"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Ngày hết hạn" v-model="item.expired_date"/>
      </v-col>
      <v-col cols="6">
        <v-select dense outlined label="Trạng thái" v-model="item.status" :items="listStatusForEdit"/>
      </v-col>
      <v-col cols="6">
        <v-select dense outlined label="Gia hạn thêm" v-model="extend" :items="extendList"/>
      </v-col>

    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'UpdateDevice',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  data: () => ({
    item: {},
    extend: null
  }),
  computed: {
    ...mapGetters({
      allUsers: 'user/all',
      listStatus: 'device/listStatus'
    }),
    listUsers() {
      return this.allUsers ? this.allUsers.map(user => {
        return {
          text: user.name,
          value: user.id
        }
      }) : [];
    },
    listStatusForEdit() {
      return this.listStatus.filter(s => s.value !== '')
    },
    extendList() {
      if (!this.item || !this.item.expired_date) return [];
      let currentDate = new Date(this.item.expired_date);
      let next1Week = new Date(new Date(this.item.expired_date).setDate(currentDate.getDate() + 7));
      let next2Week = new Date(new Date(this.item.expired_date).setDate(currentDate.getDate() + 14));
      let next1Month = new Date(new Date(this.item.expired_date).setMonth(currentDate.getMonth() + 1));
      let next2Month = new Date(new Date(this.item.expired_date).setMonth(currentDate.getMonth() + 2));
      let next3Month = new Date(new Date(this.item.expired_date).setMonth(currentDate.getMonth() + 3));
      let next6Month = new Date(new Date(this.item.expired_date).setMonth(currentDate.getMonth() + 6));
      let nextYear = new Date(new Date(this.item.expired_date).setFullYear(currentDate.getFullYear() + 1));
      return [
        {text: '---', value: null},
        {text: '1 tuần', value: next1Week.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '2 tuần', value: next2Week.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '1 tháng', value: next1Month.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '2 tháng', value: next2Month.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '3 tháng', value: next3Month.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '6 tháng', value: next6Month.toISOString().substr(0, 10) + ' 12:00:00'},
        {text: '1 năm', value: nextYear.toISOString().substr(0, 10) + ' 12:00:00'},
      ]
    }
  },
  methods: {
    ...mapActions({
      updateItem: 'device/update',
    }),
    processUpdate() {
      if (this.extend !== null) this.item.expired_date = this.extend;
      this.updateItem(this.item).then(() => {
        this.onUpdated();
        this.item = {};
      });
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) {
          this.item = this.cloneDeep(this.selectedItem);
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
